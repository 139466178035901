@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

html,
body {
  margin: 0;
  padding: 0;
}
#root,
.app {
  height: 100%;
  width: 100%;
  font-family: "Rubik", sans-serif;
}
